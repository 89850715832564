//React
import React from "react"
import { connect } from "react-redux"
import { navigate } from "@reach/router"

//Gatsby
import { Link } from "gatsby"

//Amplify
import Amplify from "@aws-amplify/core"
import Auth from "@aws-amplify/auth"
import { amplifyconfig } from "../../utils/config"

//Actions
import { loginUser } from "../../actions/userManagement"

//Components
import ForceNewPassword from "./forceNewPassword"
import Loading from "../loading"

//Icons
import { FaSpinner } from "react-icons/fa"

//Redux
const mapStateToProps = ({ userlogin, usererror, isLoading }) => {
  return { userlogin, usererror, isLoading }
}

const mapDispatchToProps = dispatch => ({
  loginUser: (username, password) => {
    dispatch(loginUser(username, password))
  },
})

//Configure
Amplify.configure(amplifyconfig)

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: ``,
      password: ``,
      error: [],
      forceNewPassword: false,
    }
    this.handleLogin = this.handleLogin.bind(this)
  }
  componentDidMount() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        console.log("actions - checkUserAuth - user")
        console.log(user)
        if (user) {
          navigate("/app/screener/")
        }
      })
      .catch(error => {
        console.log("actions - checkUserAuth - error")
        console.log(error)
      }) //end get Auth token
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleLogin() {
    const { username, password } = this.state
    this.props.loginUser(username, password)
  }

  render() {
    const { userlogin, usererror, isLoading } = this.props

    return userlogin &&
      userlogin.action &&
      userlogin.action === "forceNewPassword" ? (
      <ForceNewPassword userobject={userlogin} />
    ) : (
      <form className="form-signin w-100" autoComplete="off">
        {usererror && usererror.message ? (
          <div className="alert alert-danger text-center" role="alert">
            {usererror.message}
          </div>
        ) : null}

        <div className="display-3 text-center pb-4">Console</div>
        {/* <label className="sr-only">Email</label> */}

        <input
          className="form-control mb-2"
          onChange={this.handleUpdate}
          placeholder="Email"
          name="username"
          value={this.state.username}
          tabIndex="1"
        />

        {/* <label className="sr-only">Password</label> */}
        <input
          className="form-control mb-2"
          onChange={this.handleUpdate}
          placeholder="Password"
          name="password"
          value={this.state.password}
          type="password"
          tabIndex="2"
        />

        <button
          type="button"
          className="btn btn-primary btn-block mt-2 app-button-signin d-flex align-items-center justify-content-center"
          onClick={this.handleLogin}
          id="app-login-submit"
          tabIndex="3"
        >
          Sign in
          {isLoading ? (
            <div className="pl-2">
              <FaSpinner size={16} className="icon-spin" />
            </div>
          ) : null}
        </button>
        <Link
          className="btn btn-block my-3 blue-1"
          to="/app/password"
          tabIndex="4"
        >
          Forgot password?
        </Link>
      </form>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
