//React
import React from "react"

//Gatsby
import { StaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

const Snippet = () => (
  <StaticQuery
    query={graphql`
      query {
        homebg: file(
          relativePath: {
            eq: "stock/unsplash/joel-filipe-Wc8k-KryEPM-unsplash.jpg"
          }
        ) {
          childImageSharp {
            fluid(
              quality: 100
              maxWidth: 1920
              maxHeight: 600
              cropFocus: NORTH
              duotone: { highlight: "#66cc00", shadow: "#1d1a08" }
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div className="callout-login-container round-corners">
        <div className="callout-login p-4 w-100 text-center d-flex flex-column">
          <div class="p-4 white-1 display-3">Automate health screenings</div>
          <div class="p-2 py-3 white-1 display-4">
            Reduce risk and drive customer confidence
          </div>
          {/* <Link className="btn btn-turquoise" to="/app/signup">
            Get started
          </Link> */}
        </div>
        <div class="overlay"></div>

        <Img
          css={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            zIndex: 10,
          }}
          style={{ position: `absolute` }}
          fluid={data.homebg.childImageSharp.fluid}
        />
      </div>
    )}
  />
)

export default Snippet
